<template>
  <div style='position: relative;min-width: 320px;text-align: left'>
    <div class='border-effect'>
      <div style='display: flex;flex-direction: row;gap:5px;margin-bottom: 6px'>
        <el-avatar :size='80' :src='data.cover_pc_url' @error='true' style='min-width: 80px'>
          <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />
        </el-avatar>
        <div style='margin-left: 10px'>
          <artist-nickname :info='{artist_id:data.artist_id,nickname:data.nickname}'
                           style='font-weight: 800;font-size: 2em'  />
          <div>
            <span class='is-value' v-if='data.platform'>平台：{{ data.platform }}</span>
            <span class='is-value' v-if='data.platforms'>平台：{{ data.platforms.join('、') }}</span>
          </div>
          <div>
            <el-link v-if='data.account_id' type='primary' :title='`平台ID，点击前往KOL（${data.nickname}）主页`'
                     :href='data.home_page_link' target='_blank'>
              <span class='is-value'>{{ data.account_id || '/' }}</span>
            </el-link>
          </div>
          <div>
            <span :title='`${data.nickname}肤质`' class='is-value'>{{ data.skin_type || '肤质暂无' }}</span>
          </div>
        </div>
      </div>
      <div>
        <!--        标签：新、 惠 、 荐-->
        <div class='tag' :title='`${data.kol_label}标签`'>
          {{ data.kol_label || '标签暂无' }}
        </div>
        <div>
          <!--          红人标签-->
          <span class='tag-alias' v-if='data.tag_alias'>{{ data.tag_alias || '' }}</span>
        </div>
        <div class='form-intro intro'>
          <div v-html='data.intro'></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PanelKolInfo',
  computed: {
    ...mapGetters(['userPermissions']),
    //可查看的字段范围：不同平台配置不同的字段显示
    fieldRange() {

      let common_fields = this.setting.common_fields || []
      let extra_fields = this.setting.extra_fields || []
      return [...common_fields, ...extra_fields]
    },
  },
  props: {
    data: {
      type: Object
    },
    index: {
      type: [String, Number]
    },
    setting: {
      type: Object
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    }
  },
  methods: {
    jumpToPlcItemDetail(row) {
      this.$router.push({
        name: 'plc_item', params: { id: row.id }, query: { type: this.platformType }
      })
    },
    editIntro() {
      this.$emit('editIntro', this.data, 'intro', this.index)
    }
  }
}
</script>

<style scoped>
.border-effect {
  padding: 5px;
  /*border: #f2f2f2 1px solid;*/
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}

/**
红人标签
 */
.tag-alias {
  position: absolute;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  /*padding: 2px;*/
  right: 5px;
  top: 5px;
  line-height: 36px;
  /*height: 40px;*/
  width: 36px;
  /*border: #F56C6C 1px solid;*/
  color: #ff3176;
  opacity: 0.8;
  border: #ff3176 1px solid;
  border-radius: 50%;
  z-index: 999;
}

.form-intro {
  box-sizing: border-box;
  width: 100%;
  padding: 1px 5px;
  border: #f2f2f2 1px solid;
  min-height: 100px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 4px;
  margin: 10px auto;
}

.intro::before {
  content: '【简介】';
  font-weight: bold;
  font-size: 1.1em;
}

/*.intro::before {*/
/*  content: "简介：";*/
/*  font-weight: 600;*/
/*}*/

.edit-btn-size {
  font-size: 14px;
}

.tag {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*控制行数*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}


</style>
