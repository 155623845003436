<template>
  <div class='border-effect'>
    <div class='form-rows'>
      <div :title='`最近的广告排期`'>
        <span class='is-label'>最近排期：</span>
        <span class='is-value'>{{ data.last_release_date || '/' }}</span>
      </div>
      <div>
        <span class='is-label'>所在地区：</span>
        <span class='is-value'>{{ data.city || '/' }}</span>
      </div>
      <div v-if='fieldRange.indexOf("fans_total")>-1'>
        <span class='is-label'>粉丝(万)：</span>
        <span class='is-value'>{{ $utils.numberFormat(data.fans_total / 10000, 2) }}</span>
      </div>
      <div :title='`下月底预估粉丝量`' v-if='fieldRange.indexOf("pre_fans_total")>-1'>
        <span class='is-label'>预估粉丝(万)：</span>
        <span class='is-value'>{{ $utils.numberFormat(data.pre_fans_total / 10000, 2) }}</span>
      </div>
      <div v-if='fieldRange.indexOf("zc_total")>-1'>
        <span class='is-label'>赞藏数：</span>
        <span class='is-value'>{{ data.zc_total || '/' }} 万</span>
      </div>
      <div :title='`当月广告订单数`' v-if='fieldRange.indexOf("order_total")>-1'>
        <span class='is-label'>当月订单数：</span>
        <span class='is-value'><span>{{ data.order_total || '/' }}</span></span>
      </div>
      <div :title='`下月广告订单数`' v-if='fieldRange.indexOf("next_order_total")>-1'>
        <span class='is-label'>下月订单数：</span>
        <span class='is-value'>{{ data.next_order_total || '/' }}</span>
      </div>
      <div v-if='fieldRange.indexOf("lb_time")>-1'>
        <span class='is-label'>直播时间：</span>
        <span class='is-value'>{{ data.lb_time || '/' }}</span>
      </div>
      <div v-if='fieldRange.indexOf("lb_case")>-1'>
        <span class='is-label'>直播案例：</span>
        <span class='is-value'>{{ data.lb_case || '/' }}</span>
      </div>
      <div v-if='fieldRange.indexOf("female_fans_ratio")>-1'>
        <span class='is-label'>女粉丝比例：</span>
        <span class='is-value'>{{ data.female_fans_ratio || '/' }}</span>
      </div>
      <div v-if='fieldRange.indexOf("avg_online")>-1'>
        <span class='is-label'>平均在线：</span>
        <span class='is-value'>{{ data.avg_online || '/' }}</span>
      </div>
    </div>
    <div>
      <el-tag effect='plain' type='success' class='p-service' v-if="data.opened_star==='Y'">开通星图</el-tag>
      <el-tag effect='plain' type='success' class='p-service' v-if="data.opened_window==='Y'">开通橱窗</el-tag>
      <el-tag effect='plain' type='success' class='p-service' v-if="data.is_partner==='Y'">为品牌合作人</el-tag>
      <el-tag effect='plain' type='success' class='p-service' v-if="data.opened_cart==='Y'">开通购物车</el-tag>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PanelPlatformInfo',
  computed: {
    //可查看的字段范围：不同平台配置不同的字段显示
    fieldRange() {
      let range = []
      if (this.setting && this.setting.columns_data) {
        this.setting.columns_data.forEach((item) => {
          range.push(item.field)
        })
      }

      return range
    }
  },
  props: {
    data: {
      type: Object
    },
    setting: {
      type: Object
    }
  },
  methods: {
    openUrl(href) {
      let url = ['http://', 'https://', '//'].indexOf(href) > -1 ? href : `https://${href}`
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
.p-service {
  margin: 5px;
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}

.border-effect {
  padding: 5px;
  box-sizing: border-box;
  /*border: #f2f2f2 1px solid;*/
}

.border-effect .form-rows {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  box-sizing: border-box;

}

.border-effect .form-rows div {
  width: 49%;
  border: #f2f2f2 1px dashed;
  padding: 5px 5px;
}

</style>
