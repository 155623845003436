<template>
  <div>
    <div class='case-panel border-effect'>
      <!--                        <div class='sub-title'>-->
      <!--                          <span>商务案例</span>-->
      <!--                          <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">-->
      <!--                            <el-link :underline='false' type='primary' @click='editVal(row,"bs_case",$index)'>-->
      <!--                              <i class='el-icon-edit-outline edit-btn-size' style='color: #f2f2f2;'></i>-->
      <!--                            </el-link>-->
      <!--                          </div>-->
      <!--                        </div>-->
      <div v-html='row.bs_case' v-if='row.bs_case'
           style='width: 100%;;padding: 5px'></div>
      <div v-else style='text-align: center;vertical-align: middle'>/</div>
    </div>
    <div v-if='canEdit'>
      <el-link :underline='false' type='primary' @click='editVal'>
        <i class='el-icon-edit-outline edit-btn-size'></i>
      </el-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelBsCase',
  props: {
    rowId: {
      type: Number,
      default() {
        return 0
      }
    },
    row: {
      type: Object,
      default() {
        return { bs_case: '' }
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    editVal() {
      this.$emit('editVal', this.row, 'bs_case', this.rowId)
    }
  }
}
</script>

<style scoped>
.case-panel {
  /*width: 100%;*/
  /*padding: 10px;*/
  min-height: 300px;

}
</style>
